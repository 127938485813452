import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, BooksYear, Pagination, Scroll } from "../../components"

const BooksYearPage = ({ data, pageContext }) => {
  const {
    allContentfulBookYear: { nodes: years },
  } = data

  // console.log("poems.js data", data)
  // console.log("poems.js pagecontext", pageContext)

  return (
    <Wrapper>
      <Layout>
        <BooksYear title="Összes év" years={years} page />
        <Scroll
          // showBelow={1500}
          css="
        position: fixed; 
        right: 5rem; 
        bottom: 6rem; 
        font-size: 1.7rem;
        "
        />
        <Pagination pageContext={pageContext} />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  ${"" /* background from Poems.js  */}
  background: var(--clr-dark-5);
  padding-top: 5rem;
  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }
`

export const query = graphql`
  query BooksYearQuery($skip: Int, $limit: Int) {
    allContentfulBookYear(
      limit: $limit
      skip: $skip
      sort: { fields: [bookYear], order: [DESC] }
    ) {
      nodes {
        bookYear
        contentful_id
        book {
          bookTitle
          author {
            authorFullName
          }
          publisher {
            publisherName
          }
          bookYear {
            bookYear
          }
          bookSize
          bookImage {
            gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
          }
          bookText {
            childMarkdownRemark {
              excerpt(format: HTML, pruneLength: 100)
            }
          }
        }
      }
    }
  }
`

export default BooksYearPage
